<template>
  <div class="pt-3">
    <Loader v-if="!skills || skills.length == 0" />
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col">
        <div>
          <h4>Add Skill:</h4>
          <div class="mb-3">
            <label for="title">Title</label>
            <input type="text" v-model="newSkill.title"></input>
          </div>
          <div class="mb-3">
            <label for="description">Description</label>
            <input type="text" v-model="newSkill.description"></input>
          </div>
          <div class="mb-3">
            <label for="category">Choose Category</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="skillCategories"
              v-model="newSkill.category"
              :clearable="false"
               multiple
              >
            </v-select>
          </div>
          <div class="mb-5">
            <label for="certs">Required Certs:</label>
            <v-select
              class="mt-2"
              label="title" 
              :options="certs"
              v-model="newSkill.certs"
              :clearable="false"
               multiple
              >
            </v-select>
          </div>
          <div class="mb-3">
            <button class="btn btn__primary" @click="addSkill()">
              Add Skill
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard__container--body">
      <h4 class="mb-3">Skills:</h4>
      <vue-good-table
        :columns="columns"
        :rows="skills"
         styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          placeholder: 'Search this table',
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 25,
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'title', type: 'asc'}
        }"
        @on-row-click="onRowClick"
      >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'category'">
          <button class="chipDark mr-2 mb-2" v-for="(item,  index) in props.row.category" :key="index">
            {{item.title}}
          </button>
        </span>
        <span v-else-if="props.column.field == 'certs'">
          <button class="chipDark mr-2 mb-2" v-for="(item, index) in props.row.certs" :key="index">
            {{item.title}}
          </button>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'manageSkills',
  data: () => ({
    newSkill: {},
    // categories: ['Facility Services', 'Food and Beverage', 'Guest Services', 'Office and Clerical', 'Retail', 'Trades', 'Warehouse'],
    performingRequest: false,
    columns: [
      {
        label: 'Title',
        field: 'title',
        width: '100px',
        sortable: false
      },
      {
        label: 'Category',
        field: 'category',
        width: '240px',
        sortable: false
      },
      {
        label: 'Certs',
        field: 'certs',
        width: '240px',
        sortable: false
      },
      {
        label: 'Description',
        field: 'description',
        sortable: false
      },
    ],
    // responses: ['Passed', 'Needs Review', 'Failed Do Not Hire', 'Duplicate'],
    // columns: [
    //   {
    //     label: 'Requested',
    //     field: 'created',
    //     width: '200px'
    //   },
    //   {
    //     label: 'Type',
    //     field: 'type',
    //   },
    //   {
    //     label: 'Status',
    //     field: 'status',
    //     width: '180px'
    //   },
    //   {
    //     label: 'User',
    //     field: 'user',
    //   },
    //   {
    //     label: 'Response',
    //     field: 'response',
    //     width: '200px'
    //   },
    //   {
    //     label: 'Submitted By',
    //     field: 'orderedBy',
    //     html: true
    //   }
    // ]
  }),
  created () {
    this.$store.dispatch("getSkills")
    this.$store.dispatch("getCerts")
    this.$store.dispatch("getSkillCategories") 
    // this.$store.dispatch("updateEventJobNos")
    
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'skills', 'skillCategories', 'certs']),
  },
  components: {
    Loader,
  },
  methods: {
    // updateStatus(row) {
    //   fb.backgroundChecksCollection.doc(row.id).update(row)
    // },
    onRowClick(params) {
      let url = `/settings/skills/` + params.row.id
      console.log(url)
      router.push(url)
    },
    async addSkill() {
      this.performingRequest = true
      await fb.skillsCollection.add(this.newSkill)
      .then(
        doc => {
          fb.skillsCollection.doc(doc.id).update({
            id: doc.id
          })
        }
      )
      this.newSkill = {}
      this.performingRequest = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearCerts")
    this.$store.dispatch("clearSkills")
    this.$store.dispatch("clearSkillCategories")
  }
}
</script>